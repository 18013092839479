























































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import SearchView from '@/components/SearchView.vue';
import AppointmentListItem from '@/components/AppointmentListItem.vue';
import ListGroupItem from '@/components/ListGroupItem.vue';
import EmptyStage from '@/components/EmptyState.vue';
import {requestsStore} from '@/store/modules/requests/RequestsStore';
import {profileStore} from '@/store/modules/profile';
import {Action, Getter} from 'vuex-class';
import DialogRescheduleAppointment from '@/components/directory/customer/DialogRescheduleAppointment.vue';

@Component({
  name: 'appointments-list',
  components: {
    AppointmentListItem, SearchView, ListGroupItem,
    DialogRescheduleAppointment, EmptyStage
  }
})
export default class AppointmentsList extends Vue {
  @Prop({default: 240}) width?: number | string;
  @Prop({default: 240}) maxWidth?: number | string;
  @Prop({type: Array}) today;
  @Prop({type: Array}) upcoming;
  @Prop({type: Array}) conducted;
  @Prop({type: Array}) canceled;

  srcAppointsToday?: any[] = [];
  srcAppointsUpcoming?: any[] = [];
  srcAppointsConducted?: any[] = [];
  srcAppointsCanceled?: any[] = [];
  showHistory: boolean = false
  appointment: any | null = null;
  showDialogReschedule: boolean = false
  emptyIcon: string = ''
  emptyTitle: string = ''
  emptyDescription: string = ''

  @Getter selectedCustomer;
  @Action loadCustomerById;

  get userId() {
    return profileStore.t2bUser?.id;
  }

  get groups() {
    const {srcAppointsToday, srcAppointsUpcoming, srcAppointsConducted, srcAppointsCanceled} = this;
    return this.showHistory
        ? [
          {
            title: 'Past',
            items: srcAppointsConducted
          },
          {
            title: 'Canceled',
            items: srcAppointsCanceled
          }
        ]
        : [
          {
            title: 'Today',
            items: srcAppointsToday
          },
          {
            title: 'Upcoming',
            items: srcAppointsUpcoming
          }
        ]
  }

  get now() {
    return new Date();
  }

  get selectedAppointmentId(): string {
    const {getSelectedAppointment} = requestsStore
    return getSelectedAppointment && getSelectedAppointment.id;
  }

  get isEmpty() {
    return !this.groups.some((group) => group.items && group.items.length > 0)
  }

  get style() {
    return {
      width: `${this.width}px`,
      maxWidth: `${this.maxWidth}px`
    };
  }

  @Watch('today') onPendingChanged(value, oldValue) {
    this.srcAppointsToday = Object.assign([], value);
  }

  @Watch('upcoming') onUpcomingChanged(value, oldValue) {
    this.srcAppointsUpcoming = Object.assign([], value);
  }

  @Watch('conducted') onConductedChanged(value, oldValue) {
    this.srcAppointsConducted = Object.assign([], value);
  }

  @Watch('canceled') onCanceledChanged(value, oldValue) {
    this.srcAppointsCanceled = Object.assign([], value);
  }

  selected(id): boolean {
    return this.selectedAppointmentId === id;
  }

  search(term) {
    if (!term) {
      if (this.showHistory) {
        this.srcAppointsConducted = Object.assign([], this.conducted);
        this.srcAppointsCanceled = Object.assign([], this.canceled);
      } else {
        this.srcAppointsToday = Object.assign([], this.today);
        this.srcAppointsUpcoming = Object.assign([], this.upcoming);
      }
      return;
    }
    const searchString = term.toLowerCase();
    const fFunc = (value) => value.customer.fullName.toLowerCase().includes(searchString) || value.associate.name.toLowerCase().includes(searchString);
    if (this.showHistory) {
      this.srcAppointsConducted = this.conducted!!.filter(fFunc);
      this.srcAppointsCanceled = this.canceled!!.filter(fFunc);
    } else {
      this.srcAppointsToday = this.today!!.filter(fFunc);
      this.srcAppointsUpcoming = this.upcoming!!.filter(fFunc);
    }
  }

  selectAppointmentById(appointmentId) {
    return this.today.concat(this.upcoming).filter(item => item.id === appointmentId)[0];
  }

  onAppointmentSelected(item) {
    this.$router.push({name: 'appointments', params: {appointId: item.id}, query: this.$route.query})
  }

  async onRescheduleAppointment(appointmentId) {
    this.appointment = this.selectAppointmentById(appointmentId);
    // try {
    //   await this.loadCustomerById(customerId);
    // } catch (e) {
    //   this.$emit('error', e.message);
    // }
    //console.log('onRescheduleAppointment', appointmentId, customerId, this.selectedCustomer);
    this.showDialogReschedule = true;
    // try {
    //   // await requestsStore.rescheduleAppointment(appointmentId);
    //   this.$emit('toast', 'Appointment has been rescheduled');
    // } catch (e) {
    //   this.$emit('error', e.message);
    // }
  }

  async onCancelAppointment(appointmentId) {
    try {
      await requestsStore.cancelAppointment(appointmentId);
      this.$emit('toast', 'Appointment has been canceled');
    } catch (e: any) {
      this.$emit('error', e.message);
    }
  }

  customerPhotoUrl(appointment) {
    const customer = appointment.customer;
    return (customer && customer.photoUrl) ? customer.photoUrl.thumbnail : null;
  }

  onlineStatus(appointment): boolean {
    const customer = appointment.customer;
    return customer.status && customer.status.online;
  }

  toggleHistoryMode() {
    this.showHistory = !this.showHistory
    this.$emit('on-history-mode-changed', this.showHistory)
  }

  initEmpty() {
    this.emptyIcon = require('../assets/_empty_states_appointment.svg')
    switch (this.$route.name) {
      case 'appointments':
        this.emptyTitle = 'No appointments yet'
        this.emptyDescription = 'New appointments will appear here'
        break
    }
  }

  created() {
    this.initEmpty()
    this.srcAppointsToday = Object.assign([], this.today);
    this.srcAppointsUpcoming = Object.assign([], this.upcoming);
    this.srcAppointsConducted = Object.assign([], this.conducted);
    this.srcAppointsCanceled = Object.assign([], this.canceled);
  }

  updated() {
    this.initEmpty()
  }
};
