


























import {Component, Prop} from 'vue-property-decorator';
import AppointmentsList from '@/components/AppointmentsList.vue';
import AppointmentsCalendar from '@/components/AppointmentsCalendar.vue';
import {requestsStore} from '@/store/modules/requests/RequestsStore';
import Notifications from '@/components/mixins/Notifications';
import {mixins} from 'vue-class-component';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate'
])

@Component({
  name: 'appointments',
  components: {AppointmentsCalendar, AppointmentsList}
})
export default class Appointments extends mixins(Notifications) {
  @Prop({default: 'personal'}) subtype!: string;
  @Prop() appointId?: string;
  historyMode: boolean = false
  timeout: number = 3000;

  get forBusiness() {
    return this.subtype === 'all'
  }

  get today() {
    const {appointsTodayPersonal, appointsTodayBusiness} = requestsStore
    return this.forBusiness ? appointsTodayBusiness : appointsTodayPersonal
  }

  get upcoming() {
    const {appointsUpcomingPersonal, appointsUpcomingBusiness} = requestsStore
    return this.forBusiness ? appointsUpcomingBusiness : appointsUpcomingPersonal
  }

  get conducted() {
    const {appointsConductedPersonal, appointsConductedBusiness} = requestsStore
    return this.forBusiness ? appointsConductedBusiness : appointsConductedPersonal
  }

  get canceled() {
    const {appointsCanceledPersonal, appointsCanceledBusiness} = requestsStore
    return this.forBusiness ? appointsCanceledBusiness : appointsCanceledPersonal
  }

  changeHistoryMode(value: boolean) {
    this.historyMode = value
  }

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const {appointId, subtype} = vm
      requestsStore.loadAppointment({appointId, subtype})
    })
  }


  beforeRouteUpdate(to, from, next) {
    const appointId = to.params.appointId
    const subtype = to.query.subtype
    requestsStore.loadAppointment({appointId, subtype})
    next()
  }
}
